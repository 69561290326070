import React from 'react'
import styled from 'styled-components'
import Story from "./Story";
import story1 from '../assets/story.jpg';
import story2 from '../assets/story2.jpg';
import story3 from '../assets/story3.jpg';
import story4 from '../assets/story4.jpg';
import story5 from '../assets/story5.jpg';
import story6 from '../assets/story6.jpg';
import story7 from '../assets/story7.jpg';
import story8 from '../assets/story8.jpg';
import story9 from '../assets/story9.jpg';
import story10 from '../assets/story10.jpg';



export default function Storybar() {
    return (
        <div id='parent'>
        <Wrapper id='child'>
            <Story image={story1} title='50% OFF'/>
            <Story image={story2} title='FREE SHIPPING'/>
            <Story image={story3} title='BLACK FRIDAY'/>
            <Story image={story4} title='BOGO'/>
            <Story image={story5} title='SKI LINE'/>
            <Story image={story6} title='PROMO'/>
            <Story image={story7} title='⚠️⚠️⚠️'/>
            <Story image={story8} title='FREE SHIPPING'/>
            <Story image={story9} title='BOGO'/>
            <Story image={story10} title='FREE SHIPPING'/>
        </Wrapper>
        </div>
    )
}

const Wrapper =styled.div` 
    cursor: grab;
    display: flex;
    overflow: auto;
    width: 100%;
    scrollbar-width: none;
    scroll-behavior: smooth;
    background-image: linear-gradient(black, transparent);
    margin-bottom: 30px;
`