import Home from "./components/Home";
import Header from "./components/Header";
import Products from "./components/Products";
import Product from "./components/Product";

//import Navbar from "./components/Navbar";

import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="shop" element={<Products />} />
        <Route path="shop/:productId" element={<Product />} />
      </Routes>
    </Router>
  );
}

export default App;
