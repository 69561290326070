import Hero from "./Hero";
import Cardbar from "./Cardbar";
import Storybar from "./Storybar.js"

export default function Home() {
  return (
    <div>
        <Storybar />
      <Hero />

      <Cardbar />
    </div>
  );
}
