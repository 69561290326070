import React from 'react'
import styled from 'styled-components'


export default function Story(props) {
    return (
    <Wrapper>
    <Out>
        <Circ style={{backgroundImage: `url(${props.image})`}}>
            
        </Circ>
    </Out>
    <div id='title'>{props.title}</div>
    </Wrapper>
    )
}
const Wrapper = styled.div` 
display: flex;
flex-direction: column;
text-align: center;
margin-left: 20px;
#title{
        font-size: 12px;
        user-select: none;
    }

`
const Circ = styled.div` 
   
    border-radius: 60px;
    height: 54px;
    width: 54px;
    margin:3px;
    background-size: 60px;
`
const Out = styled.div` 
    border: 1.5px solid #fff;
    box-shadow: 0 0 5px 1px #0ff, 0 0 5px 1px #0ff inset;
    border-radius: 60px;
    height: 60px;
    width: 60px;
    margin-top: 8px;
    margin-bottom: 8px;
    
    
`
/* 
border: 1px solid #fff;
padding: 4px;
background: rgba(0, 255, 255, 0.05);
box-shadow: 0 0 5px 1px #0ff, 0 0 5px 1px #0ff inset;
margin:20px; 
*/