import React from "react";
import Card from "./Card";
import pink from "../assets/pink360.gif";
import blue from "../assets/blue360.gif";
import black from "../assets/black360.gif";
import red from "../assets/red360.gif";
import white from "../assets/white360.gif";
import rednblue from "../assets/rednBlue360.gif";
import styled from "styled-components";

export default function Cardbar() {
  return (
    <Section>
      <div id='title'><p>SELECT MODEL</p></div>
      <Wrapper>
        <Card image={pink} title='PINK' price='40'></Card>
        <Card image={blue} title='SAPPHIRE' price='40'></Card>
        <Card image={red} title='RED' price='40'></Card>
        <Card image={white} title='WHITE' price='40'></Card>
        <Card image={black} title='BLACK' price='40'></Card>
        <Card image={rednblue} title='BORDAISE' price='40'></Card>
      </Wrapper>
    </Section>
  );
}

const Section = styled.div`

  padding-top: 20px;
  background: rgba(0, 255, 255, 0.05);
  #title{
    padding-left: 10px;
  }
`;
const Wrapper = styled.div`
cursor: grab;
  margin-top:-14px;
  width: 100%;
  display: flex;
  overflow: scroll;
`;
