import React from "react";
import logo from "../assets/sclogo.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <Wrapper>
      <Link to="/"><img src={logo} alt="Speed Commanders" /></Link>
      
      </Wrapper>
      
    </>
  );
}
const Wrapper = styled.div`
  width: 100%;
  background-image: linear-gradient(black, transparent);
  img {
    width: 60%;
    margin-top: 10px;
    margin-left: 20%;
  }
`;
