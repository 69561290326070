import React from "react";
import styled from "styled-components";
import pinkpic from "../assets/pinkpic.jpg";
import { Link } from "react-router-dom";


export default function Hero() {
  return (
    
    <Wrapper style={{ backgroundImage: `url(${pinkpic})` }}>
      <h1>Black</h1>
      <h1>Friday</h1>
      <h1>Limited</h1>
      <h1>Giveaway</h1>
      <Link to="/shop">
          <div id='button'><h1>shop now</h1></div>
      </Link>
      
    </Wrapper>
    
  );
}
const Wrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 78vw;
  background-size: 100vw;
  border: 1px solid #fff;
  box-shadow: 0 0 5px 1px #0ff, 0 0 5px 1px #0ff inset;
  h1,
  h3 {
    margin: 6px;
  }
  a{
      text-decoration: none !important;
  }
  #button {
    background-color: black;
    color: white;
    display: block;
    padding-left: 8px;
    padding-right: 8px;
    border: solid white 1px;
    margin: 6px;
  }
`;
