import { SpinViewer } from "@egjs/react-view360";
import React from "react";
import sprite from "../assets/scsprite.png";
import styled from "styled-components";

export default function spin() {
  return (
    <Wrapper>
      
      <SpinViewer
        tag="div"
        imageUrl={sprite}
        rowCount={7}
        colCount={7}
        scale={3}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #fff;
  padding: 4px;
  background: rgba(0, 255, 255, 0.05);
  box-shadow: 0 0 5px 1px #0ff, 0 0 5px 1px #0ff inset;
  margin:20px;
`;
