import React from "react";
import "remixicon/fonts/remixicon.css";
import styled from "styled-components";

export default function Card(props) {
  return (
    <Wrapper>
      <Top style={{ backgroundImage: `url(${props.image})` }}>
        <div className="title">{props.title}</div>
        <div className="price">
          <div className="pricenum">{props.price}</div>
          <div className="dollarsign">$</div>
        </div>
      </Top>
      <Bottom>
        <div className="icons">
          <div className="icon">
            <i className="ri-sun-fill" />
          </div>
          <div className="icon">
            <i className="ri-moon-fill" />
          </div>
          <div className="icon">
            <i className="ri-windy-line" />
          </div>
        </div>
        <div id="stars">
          <i className="ri-star-fill" style={{ color: "#00ffff" }}></i>
          <i className="ri-star-fill" style={{ color: "#00ffff" }}></i>
          <i className="ri-star-fill" style={{ color: "#00ffff" }}></i>
          <i className="ri-star-fill" style={{ color: "#00ffff" }}></i>
          <i className="ri-star-fill" style={{ color: "#00ffff" }}></i>
        </div>
      </Bottom>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  cursor: pointer;
  margin: 10px;
  min-width: 80vw;

  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #fff;
  padding: 4px;
  background: black;
  box-shadow: 0 0 5px 1px #0ff, 0 0 5px 1px #0ff inset;
  
  

`;
const Top = styled.div`
  position: relative;
  aspect-ratio: 3/1;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0% 90%;

  .title {
    color: white;
    padding-left: 8px;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 30px #000000, 0 0 40px #000000, 0 0 55px #000000, 0 0 75px #000000;
  }
  .price {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    border: solid black;
    .pricenum {
      color: #14366F;
      background-color: #66ffff;
      padding: 4px;
    }
    .dollarsign {
      color: #66ffff;
      background-color: #14366F;
      padding: 4px;
    }
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  #stars{
    margin-top:2px;
    margin-right: 4px;
  }
  .icons {
    display: flex;
    .icon {
      color: #66ffff;
      background-color: #001a1a;
      padding: 0.5px;
      margin-top: 2px;
      margin-left:2px;
      width: 20px;
      height: 20px;
      text-align: center;
    
    }
  }
`;
