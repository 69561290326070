import Card from "./Card";
import productsData from "./productsData";

import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Products() {
  const products = productsData.map((product) => {
    return (
      <div key={product.id}>
        <h3>
          <Link to={`/shop/${product.id}`}><Card image={product.gif} title={product.name} price="40" /></Link>
        </h3>
        
      </div>
    );
  });

  return (
    <Wrapper>
      <h1>Shop</h1>
      {products}
  
      
    </Wrapper>
  );
}

const Wrapper = styled.div`

`;
