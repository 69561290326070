import Spin from './Spin.js'
import productsData from "./productsData";
import { useParams } from 'react-router'

export default function Productpage() {
    
    const {productId} = useParams()
    const thisProduct = productsData.find(prod => prod.id === productId)
    
    return (
        <div>
            <h1>{thisProduct.name}</h1>
            <p>Price: ${thisProduct.price}</p>
            <Spin />
            <p>{thisProduct.description}</p>
        </div>
    )
}

  
