import blue from "../assets/blue360.gif"
import pink from "../assets/pink360.gif"
import red from "../assets/red360.gif"
import black from "../assets/black360.gif"
import white from "../assets/white360.gif"
import bordaise from "../assets/rednBlue360.gif"

export default [
    {
        id: "1",
        name: "Saphire",
        price: 40,
        gif: blue,
        description: "here is some texct this is a description"
    },
    {
        id: "2",
        name: "Neon",
        price: 40,
        gif: pink,
        description: "here is some texct this is a description"
    },
    {
        id: "3",
        name: "Red",
        price: 40,
        gif: red,
        description: "here is some texct this is a description"
    },
    {
        id: "4",
        name: "Black",
        price: 40,
        gif: black,
        description: "here is some texct this is a description"
    },
    {
        id: "5",
        name: "White",
        price: 40,
        gif: white,
        description: "here is some texct this is a description"
    },
    {
        id: "6",
        name: "Bordaise",
        price: 40,
        gif: bordaise,
        description: "here is some texct this is a description"
    },

];

